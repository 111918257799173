// extracted by mini-css-extract-plugin
export var inputGroupSm = "index-styles-module--input-group-sm--b8Ra_";
export var inputGroupLg = "index-styles-module--input-group-lg--178dp";
export var container = "index-styles-module--container--3_hzE";
export var anchorExplore = "index-styles-module--anchor-explore--CJQpf";
export var placeholder = "index-styles-module--placeholder--14M6Z";
export var hero = "index-styles-module--hero--2P_-e";
export var intro = "index-styles-module--intro--3L105";
export var quote = "index-styles-module--quote--1xefC";
export var quoteText = "index-styles-module--quote-text--3MDpc";
export var headingSection = "index-styles-module--heading-section--3IUKA";