import React, { Fragment, SyntheticEvent } from "react";
import classnames from "classnames";
import { toHyphenFromCamelCase } from "../../../helpers/Formatting";

import { Categories } from "../../../../types/categories";

import * as s from "./styles.module.scss";

interface ICategoriesSelectorProps {
    activeCat?: string;
    activeSubCat?: string;
}

export default function CategoriesSelector(props: ICategoriesSelectorProps) {
    const cats = Object.keys(Categories);

    let { activeCat } = props;

    const clickHandler = (e: SyntheticEvent, catKey: string) => {
        if (activeCat === catKey) {
            e.preventDefault();
        }
    };

    const renderCat = (cat: string, index: number) => {
        const catKey = cats[index];

        return (
            <Fragment key={`cat-selector-${index}`}>
                <a
                    className={classnames(s[catKey], {
                        [s.active]: activeCat === catKey,
                    })}
                    href={`/${toHyphenFromCamelCase(catKey)}/#explore`}
                    onClick={(e) => clickHandler(e, catKey)}
                >
                    {cat}
                </a>
                {index + 1 < cats.length && (
                    <>
                        <span className={`${s.divider} hidden-xs`}>
                            &middot;
                        </span>
                        <br className="hidden-sm hidden-md hidden-lg" />
                    </>
                )}
            </Fragment>
        );
    };

    return (
        <>
            <div className={s.categoriesSelectorLine} />
            <div className={s.categoriesSelector}>
                {cats.map((cat, index) => renderCat(Categories[cat], index))}
            </div>
        </>
    );
}
