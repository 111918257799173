// extracted by mini-css-extract-plugin
export var inputGroupSm = "styles-module--input-group-sm--3vRAD";
export var inputGroupLg = "styles-module--input-group-lg--3BBkl";
export var categoriesSelectorLine = "styles-module--categoriesSelectorLine--2rX2k";
export var categoriesSelector = "styles-module--categories-selector---cjuj";
export var divider = "styles-module--divider--1da9L";
export var active = "styles-module--active--2i51N";
export var deepSky = "styles-module--deepSky--3fAAm";
export var starscape = "styles-module--starscape--15Hmx";
export var solarSystem = "styles-module--solarSystem--3pjRZ";
export var events = "styles-module--events--LSo0S";