const formatCurrency = (value: number) => {
    return `£${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
};

const pluraliseTerm = (term: string, value: number): string => {
    return value > 1 ? term + "s" : term;
};

const padWithEllipsis = (characterLimit: number, ellipsis: string) => (
    s: string
): string => {
    if (s.length === characterLimit) return s;

    if (s.length < characterLimit) {
        const diff = characterLimit - s.length;

        if (diff <= ellipsis.length) {
            return s
                .slice(0, characterLimit - ellipsis.length)
                .padEnd(characterLimit, ellipsis);
        }

        return s;
    }

    return s
        .slice(0, characterLimit - ellipsis.length)
        .padEnd(characterLimit, ellipsis);
};

const formatPostcode = (value: string): string => {
    if (
        value.length === 0 ||
        value.length <= 4 ||
        value.charAt(value.length - 4) === " "
    )
        return value.toUpperCase();

    return value
        .replace(/\s/g, "")
        .substr(0, value.length - 3)
        .concat(" ")
        .concat(value.slice(-3))
        .toUpperCase();
};

const priceFormatter = {
    format: (value: number) => {
        return new Intl.NumberFormat("en-GB", {
            style: "currency",
            currency: "GBP",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(value);
    },
};

const numberFormatter = {
    format: (value: number, decimalPlaces: number) => {
        return new Intl.NumberFormat("en-GB", {
            minimumFractionDigits: decimalPlaces,
            maximumFractionDigits: decimalPlaces,
        }).format(value);
    },
};

const toHyphenFromWords = (str: string): string => {
    return str.replace(/\s/g, "-").toLowerCase();
};

const toHyphenFromCamelCase = (str: string): string => {
    return str.replace(/[A-Z]/g, m => "-" + m.toLowerCase());
};

const toCamelCase = (str: string): string => {
    return str
        .replace(/(?:^\w|[A-Z]|\b\w)/g, (letter, i) =>
            i === 0 ? letter.toLowerCase() : letter.toUpperCase()
        )
        .replace(/\s+/g, "");
};

export {
    formatCurrency,
    formatPostcode,
    pluraliseTerm,
    padWithEllipsis,
    priceFormatter,
    numberFormatter,
    toHyphenFromWords,
    toHyphenFromCamelCase,
    toCamelCase,
};
