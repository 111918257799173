import React, { useState, useEffect, useRef } from "react";
import { graphql } from "gatsby";
import "react-image-lightbox/style.css";

import Card from "../components/molecules/Card";
import domains from "../configuration/domains";
import Heading from "../components/atoms/Heading";
import LightboxFn from "../components/molecules/LightboxFn";
import Page from "../components/organisms/Page";
import CategoriesSelector from "../components/molecules/CategoriesSelector";
import { getImgResponsiveSet } from "../helpers/Contentful/images";

import { IIndexProps } from "../../types/pages/index.types";

import * as s from "../../styles/pages/index.styles.module.scss";

const { baseUrl } = domains;

export const query = graphql`
    query {
        site {
            siteMetadata {
                title
                description
            }
        }
        allContentfulAstrophoto(
            filter: { node_locale: { eq: "en-US" }, type: { eq: "Deep Sky" } }
            sort: { fields: [date], order: DESC }
        ) {
            edges {
                node {
                    id
                    title
                    type
                    target
                    link
                    shortDescription
                    image {
                        description
                        file {
                            url
                        }
                    }
                }
            }
        }
    }
`;

export default function DeepSky(props: IIndexProps) {
    const astrophotos = props.data.allContentfulAstrophoto.edges.map(
        (edge) => edge.node
    );
    if (!astrophotos) return null;
    const { title, description } = props.data.site.siteMetadata;

    const image = astrophotos[0].image;
    const imgResponsiveSet = getImgResponsiveSet(image.file.url);

    const scrollingRef = useRef();
    const hash = props.location.hash.replace("#", "");

    const [cardIndex, setCardIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);

    const CURRENT_CAT = "Deep Sky";

    useEffect(() => {
        setTimeout(() => {
            scrollIntoSection();
        }, 250);
    }, []);

    function scrollIntoSection() {
        if (hash) {
            const node = document.getElementById(hash);
            if (node) {
                node.scrollIntoView({ behavior: "smooth" });
            }
        }
    }

    function callbackCardsFn(index: number): void {
        if (!isOpen) setIsOpen(true);
        setCardIndex(index);
    }

    function renderCards(): JSX.Element | null | any {
        return astrophotos.map(
            ({ id, image, title, shortDescription, link, type }, key) => (
                <div className="col-sm-6" key={id}>
                    <Card
                        index={key}
                        image={image}
                        title={title}
                        description={shortDescription}
                        link={link}
                        type={type}
                        callback={callbackCardsFn}
                    />
                </div>
            )
        );
    }

    return (
        <Page
            metaTitle={title}
            canonicalUrl={`${baseUrl}`}
            metaDescription={description}
            heroImages={[
                imgResponsiveSet.mobile.jpg, //768 Mobile
                imgResponsiveSet.tablet.jpg, // 992 Tablet
                imgResponsiveSet.desktop.jpg, // 2880 Desktop
            ]}
            openGraph={{ img: imgResponsiveSet.tablet.jpg, url: baseUrl }}
            isFullBanner={false}
            currentCat={CURRENT_CAT}
        >
            <LightboxFn
                cards={astrophotos}
                cardIndex={cardIndex}
                isOpen={isOpen}
                setCardIndex={setCardIndex}
                setIsOpen={setIsOpen}
            />

            <div id="explore" className={s.anchorExplore} ref={scrollingRef} />
            <div className={s.quote}>
                <Heading level={2} size="xsmall" className={s.quoteText}>
                    "We are part of this universe; we are in this universe,
                    <br className="hidden-xs" />
                    but perhaps more important than both of those facts,
                    <br className="hidden-xs" /> is that the universe is in us."
                </Heading>
                <span>
                    Neil deGrasse Tyson (1958 - present day), astrophysicist,
                    cosmologist, planetary scientist, author &amp; science
                    communicator
                </span>
            </div>

            <CategoriesSelector activeCat="deepSky" />
            <div className={s.container}>
                <div className={s.headingSection}>
                    <Heading level={3} size="small">
                        {CURRENT_CAT} Images
                    </Heading>
                </div>
                <div className="row row-flex">{renderCards()}</div>
            </div>
        </Page>
    );
}
