// extracted by mini-css-extract-plugin
export var inputGroupSm = "styles-module--input-group-sm--ywCb_";
export var inputGroupLg = "styles-module--input-group-lg--1esyl";
export var card = "styles-module--card--1CupD";
export var contain = "styles-module--contain--LudCC";
export var heading = "styles-module--heading--1COuW";
export var deepSky = "styles-module--deepSky--3CKNI";
export var starScape = "styles-module--starScape--1RUXw";
export var solarSystem = "styles-module--solarSystem--m1d9j";
export var events = "styles-module--events--2zPBl";
export var mobile = "styles-module--mobile--Js76I";
export var type = "styles-module--type--1IXrw";
export var text = "styles-module--text--1410-";
export var hovered = "styles-module--hovered--2uOev";
export var image = "styles-module--image--2Syu8";
export var content = "styles-module--content--1TmhS";
export var explore = "styles-module--explore--Vp2v6";
export var expandWrapper = "styles-module--expand-wrapper--2ZJZc";
export var link = "styles-module--link--1BoxT";
export var linkMobile = "styles-module--link-mobile--1knFM";