import React, { useState } from "react";
import { ICardProps } from "./types";
import Heading from "../../atoms/Heading";
import classnames from "classnames";
import { isIE, isEdge, isSafari } from "../../../helpers/Browser";

import * as s from "./styles.module.scss";

export default function Card(props: ICardProps) {
    const {
        index,
        image,
        title,
        description,
        link,
        type,
        callback,
        showType = false,
    } = props;
    const [isHovered, setIsHovered] = useState(false);

    const imgWidth = 1230;
    const imgQuality = 60;
    const webp = image
        ? `${image.file.url}?fm=webp&w=${imgWidth}&q=${imgQuality}`
        : "";
    const jpg = image
        ? `${image.file.url}?fm=jpg&w=${imgWidth}&q=${imgQuality}`
        : "";
    const imgDescription = image ? image.description : "";

    function handleHover(isHoveredVal: boolean): void {
        setIsHovered(isHoveredVal);
    }

    return (
        <>
            <div
                className={classnames(
                    s.card,
                    s.mobile,
                    "hidden-sm",
                    "hidden-md",
                    "hidden-lg",
                    {
                        [s.deepSky]: type === "Deep Sky",
                        [s.starScape]: type === "Starscape",
                        [s.solarSystem]: type === "Solar System",
                        [s.events]: type === "Events",
                    }
                )}
            >
                <a className={s.linkMobile} href={link}>
                    {!isSafari && (
                        <picture>
                            <>
                                <source srcSet={webp} />
                                <source srcSet={jpg} />
                            </>
                            <img
                                src={jpg}
                                alt={imgDescription}
                                className={classnames({
                                    [s.contain]: isIE || isEdge,
                                })}
                            />
                        </picture>
                    )}
                    {isSafari && <img src={jpg} alt={imgDescription} />}

                    {showType && (
                        <div
                            className={classnames(s.type, {
                                [s.deepSky]: type === "Deep Sky",
                                [s.starScape]: type === "Starscape",
                                [s.solarSystem]: type === "Solar System",
                                [s.events]: type === "Events",
                            })}
                        >
                            {type}
                        </div>
                    )}
                    <div className={s.content}>
                        <Heading
                            size="xsmall"
                            level={3}
                            className={classnames(s.heading, {
                                [s.deepSky]: type === "Deep Sky",
                                [s.starScape]: type === "Starscape",
                                [s.solarSystem]: type === "Solar System",
                                [s.events]: type === "Events",
                            })}
                        >
                            {title}
                        </Heading>
                    </div>
                </a>
            </div>

            <div
                className={classnames(s.card, "hidden-xs", {
                    [s.deepSky]: type === "Deep Sky",
                    [s.starScape]: type === "Starscape",
                    [s.solarSystem]: type === "Solar System",
                    [s.events]: type === "Events",
                })}
                onMouseEnter={() => handleHover(true)}
                onMouseLeave={() => handleHover(false)}
            >
                <div
                    className={classnames(s.expandWrapper, {
                        [s.hovered]: isHovered,
                    })}
                    onClick={() => callback(index)}
                >
                    <span className={s.link}>Click to Expand</span>
                </div>
                {showType && (
                    <div
                        className={classnames(s.type, {
                            [s.deepSky]: type === "Deep Sky",
                            [s.starScape]: type === "Starscape",
                            [s.solarSystem]: type === "Solar System",
                            [s.events]: type === "Events",
                        })}
                    >
                        {type}
                    </div>
                )}

                {!isSafari && (
                    <picture
                        className={s.image}
                        onClick={() => callback(index)}
                    >
                        <source srcSet={webp} />
                        <source srcSet={jpg} />

                        <img
                            src={jpg}
                            alt={imgDescription}
                            className={classnames("", {
                                [s.contain]: isIE || isEdge,
                            })}
                        />
                    </picture>
                )}
                {isSafari && (
                    <img className={s.image} src={jpg} alt={imgDescription} />
                )}

                <div className={s.content}>
                    <Heading
                        size="xsmall"
                        level={3}
                        className={classnames(s.heading, {
                            [s.deepSky]: type === "Deep Sky",
                            [s.starScape]: type === "Starscape",
                            [s.solarSystem]: type === "Solar System",
                            [s.events]: type === "Events",
                        })}
                    >
                        {title}
                    </Heading>
                    <div
                        className={classnames(s.text, {
                            [s.hovered]: isHovered,
                        })}
                    >
                        <div>{description}</div>
                        <div className={s.explore}>
                            <a className={s.link} href={link}>
                                Explore
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
